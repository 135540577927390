var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoiceHistory.length > 0
    ? _c(
        "div",
        [
          _c("div", { staticClass: "history-header mb-3" }, [
            _vm._m(0),
            _c("span", [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "fa fa-sort mr-3",
                attrs: { title: "Sort Submission by date" },
                on: {
                  click: function($event) {
                    return _vm.sortHistory()
                  }
                }
              }),
              _vm.toggleHistory
                ? _c("i", {
                    staticClass: "fas fa-minus-circle danger",
                    on: {
                      click: function($event) {
                        _vm.toggleHistory = !_vm.toggleHistory
                      }
                    }
                  })
                : _c("i", {
                    staticClass: "fas fa-plus-circle success",
                    on: {
                      click: function($event) {
                        _vm.toggleHistory = !_vm.toggleHistory
                      }
                    }
                  })
            ])
          ]),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.toggleHistory,
                id: "invoice-history-toggller"
              }
            },
            [
              _vm.invoiceHistory.length > 0 && _vm.history.accountant_mdate
                ? _c("div", { staticClass: "mb-3" }, [
                    _vm.history.accountant_paid === 1
                      ? _c("p", { staticClass: "mb-0" }, [
                          _c("strong", { staticClass: "success" }, [
                            _vm._v("Paid On:")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  _vm.history.accountant_mdate,
                                  "MM/DD/YYYY hh:mm A"
                                )
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm.history.ac_note
                      ? _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.editChequeNumbers,
                                expression: "!editChequeNumbers"
                              }
                            ],
                            staticClass: "mb-0"
                          },
                          [
                            _vm._v("Accountant Note: "),
                            _c("pre", [_vm._v(_vm._s(_vm.history.ac_note))])
                          ]
                        )
                      : _vm._e(),
                    _vm.history.check_round_date && !_vm.editChequeNumbers
                      ? _c("p", { staticClass: "mb-1" }, [
                          _c("strong", [
                            _vm._v(
                              "Cheque Round Date: " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.history.check_round_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " | Cheque Number: " +
                                _vm._s(_vm.history.check_number)
                            )
                          ])
                        ])
                      : _vm._e(),
                    !_vm.history.check_cleared
                      ? _c("p", { staticStyle: { color: "#c00606" } }, [
                          _c("strong", [
                            _c("i", {
                              staticClass: "fas fa-exclamation-triangle",
                              staticStyle: { color: "#c00606" }
                            }),
                            _c("em", [
                              _vm._v(
                                "The payment information of this invoice is being changed."
                              )
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._l(_vm.invoiceHistory, function(value) {
                return _c(
                  "div",
                  { staticClass: "invoice-history-body d-block mb-3" },
                  [
                    _c(
                      "span",
                      {
                        class: {
                          danger: value.action_status == -1,
                          success: value.action_status == 5,
                          warning: value.action_status == 4,
                          info: value.action_status == 3
                        }
                      },
                      [_c("b", [_vm._v(_vm._s(value.action) + " By: ")])]
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(value.role) +
                          " " +
                          _vm._s(value.userName) +
                          " at (" +
                          _vm._s(
                            _vm._f("moment")(
                              value.date,
                              "MM/DD/YYYY hh:mm:ss A"
                            )
                          ) +
                          ")"
                      )
                    ]),
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              "rejectionError" in value.invoiceData &&
                              value.invoiceData.rejectionError,
                            expression:
                              "'rejectionError' in value.invoiceData && value.invoiceData.rejectionError"
                          }
                        ],
                        staticClass:
                          "fa fa-exclamation-triangle danger float-right"
                      },
                      [_vm._v(" Failed To Send Email")]
                    ),
                    _c("textarea", {
                      staticClass: "form-control",
                      attrs: { readonly: "" },
                      domProps: { value: value.comments }
                    })
                  ]
                )
              })
            ],
            2
          ),
          _c("hr"),
          _vm.invoiceFiles.length > 0
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _vm._m(1),
                  _vm._l(_vm.invoiceFiles, function(file) {
                    return _c("p", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function($event) {
                              return _vm.getFile(file.filename)
                            }
                          }
                        },
                        [_vm._v(_vm._s(file.filename))]
                      ),
                      [2, 5, 14, 24, 34, 44, 54, 17].includes(
                        _vm.history.inv_passed
                      )
                        ? _c("span", [
                            _vm.$session.get("user") == "vendor" ||
                            _vm.$session.get("user") == "accountant"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger ml-2",
                                    attrs: { type: "button", name: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFile(file)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v("Remove File "),
                                    _c("i", {
                                      staticClass: "fa fa-minus-circle"
                                    })
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _c("hr"),
          _vm.invoiceModificationHistoryChanges.length > 0
            ? _c(
                "div",
                [
                  _vm._m(2),
                  _vm._m(3),
                  _vm._l(_vm.invoiceModificationHistoryChanges, function(
                    group
                  ) {
                    return _c(
                      "div",
                      {
                        staticClass: "row ml-0 mr-0 text-center",
                        staticStyle: {
                          "border-bottom": "2px solid #444",
                          "border-top": "2px solid #444"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-xl-12",
                            staticStyle: { background: "#f9f9f9" }
                          },
                          [
                            _c("h5", { staticClass: "mb-0 py-2" }, [
                              _vm._v(
                                "Resubmission Or Saved As Draft Date: " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      group.date,
                                      "MM/DD/YYYY hh:mm:ss A"
                                    )
                                  )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xl-4",
                            staticStyle: {
                              background: "#fff97a45",
                              "padding-top": "15px"
                            }
                          },
                          _vm._l(group.groupedUpdatedServices, function(
                            change,
                            i
                          ) {
                            return group.groupedUpdatedServices.length > 0
                              ? _c("div", { staticClass: "mb-3" }, [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _c("strong", [
                                      _c(
                                        "span",
                                        { staticClass: "text-capitalize" },
                                        [_vm._v(_vm._s(change.name))]
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "pre",
                                    _vm._l(change.code, function(line) {
                                      return _c("p", { staticClass: "mb-0" }, [
                                        line.comment
                                          ? _c("span", [
                                              _vm._v(_vm._s(line.comment))
                                            ])
                                          : _vm._e()
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              : _vm._e()
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xl-4",
                            staticStyle: {
                              background: "#4bdf603d",
                              padding: "15px"
                            }
                          },
                          _vm._l(group.addedServices, function(line, i) {
                            return group.addedServices.length > 0
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [
                                    _c(
                                      "span",
                                      { staticClass: "text-capitalize" },
                                      [_vm._v(_vm._s(line.service_name))]
                                    )
                                  ]),
                                  _vm._v(":"),
                                  _c("pre", [
                                    line.comment
                                      ? _c("span", [
                                          _vm._v("$" + _vm._s(line.comment))
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              : _vm._e()
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-xl-4",
                            staticStyle: {
                              background: "#b9353540",
                              padding: "15px"
                            }
                          },
                          _vm._l(group.removedServices, function(line, i) {
                            return group.removedServices.length > 0
                              ? _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [
                                    _c(
                                      "span",
                                      { staticClass: "text-capitalize" },
                                      [_vm._v(_vm._s(line.service_name))]
                                    )
                                  ]),
                                  _vm._v(":"),
                                  _c("pre", [
                                    line.comment
                                      ? _c("span", [
                                          _vm._v("$" + _vm._s(line.comment))
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              : _vm._e()
                          }),
                          0
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("i", {
        staticClass: "fa fa-history",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(" Invoice History")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-3" }, [
      _c("i", { staticClass: "fal fa-file mr-2" }),
      _vm._v(" Invoice Files")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-3" }, [
      _c("i", { staticClass: "fal fa-clock mr-2" }),
      _vm._v(" Invoice Changes")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row ml-0 mr-0 text-center" }, [
      _c(
        "div",
        {
          staticClass: "col-xl-4",
          staticStyle: { background: "#fff97a45", "padding-top": "15px" }
        },
        [
          _c("h4", { staticClass: "mb-0 pb-0" }, [
            _vm._v("Updates "),
            _c("i", { staticClass: "fa fa-edit" })
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col-xl-4",
          staticStyle: { background: "#4bdf603d", padding: "15px" }
        },
        [
          _c("h4", { staticClass: "mb-0 pb-0" }, [
            _vm._v("Additions "),
            _c("i", { staticClass: "fa fa-plus" })
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col-xl-4",
          staticStyle: { background: "#b9353540", padding: "15px" }
        },
        [
          _c("h4", { staticClass: "mb-0 pb-0" }, [
            _vm._v("Removals "),
            _c("i", { staticClass: "fa fa-minus-circle" })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }